import { render, staticRenderFns } from "./about.vue?vue&type=template&id=1b44e094&"
import script from "./about.vue?vue&type=script&lang=ts&"
export * from "./about.vue?vue&type=script&lang=ts&"
import style0 from "./about.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AboutHeader: require('/home/node/client/components/About/AboutHeader.vue').default})
