









import { Component, Vue } from 'nuxt-property-decorator'
import AboutHeader from '@client/components/About/AboutHeader.vue'

@Component({
	components: {
		AboutHeader
	}
})
export default class AboutLayout extends Vue {

}
